import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import FilterSearch from 'pages/parts/FilterSearch'
import Pagination from 'pages/parts/Pagination'
import TableControl from 'pages/parts/TableControl'

import { GET, PGET } from 'helpers/api'
import { formatMoney, formatDateWithTime, findFromArrayById } from 'helpers/helpers'

function Index() {

	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const URL = `/services/web/api/price-history-pageList/`

	const [pageCount, setPageCount] = useState(0)

	const [modalTableControl, setModalTableControl] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		search: '',
	})
	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])

	async function getData() {
		checkFilter()
		const response = await PGET(URL + filterData.posId, filterData)
		if (response && response.data) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			search: '',
		})
		getData()
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL + filterData.posId, { ...filterData, page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper", {}, { loader: true })
		if (response) {
			setPoses(response)
		}

	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getData(true)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('HISTORY')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-primary" onClick={() => getData(true)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block mb-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.pos) &&
										<th>{t('POS')}</th>
									}
									<th>{t('CREATED_BY')}</th>
									<th>{t('NAME_OF_PRODUCT')}</th>
									{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.categoryName) &&
										<th>{t('CATEGORY')}</th>
									}
									<th>{t('BARCODE')}</th>
									{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.artikul) &&
										<th className="text-center">{t('ARTIKUL')}</th>
									}
									<th className="text-center">{t('ADMISSION_PRICE')}</th>
									<th className="text-center">{t('WHOLESALE_PRICE')}</th>
									{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.bankPrice) &&
										<th className="text-center">{t('BANK_PRICE')}</th>
									}
									<th className="text-center">{t('UNIT_PRICE')}</th>
									{/* <th className="text-center">{t('LOGIN')}</th> */}
									<th className="text-center table-control">
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('DATE_OF_CREATION')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.pos) &&
											<td>{item.posName}</td>
										}
										<td>{item.createdBy}</td>
										<td>{item.productName}</td>
										{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.categoryName) &&
											<td>{item.categoryName}</td>
										}
										<td>{item.productBarcode}</td>
										{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.artikul) &&
											<td>{item.artikul}</td>
										}
										<td>
											<div className="d-flex justify-content-center">
												<div className="me-2">{formatMoney(item.oldPrice)}</div>
												<div className="me-2">
													<i className="uil uil-arrow-right"></i>
												</div>
												{formatMoney(item.newPrice)}
											</div>
										</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="me-2">{formatMoney(item.oldWholesalePrice)}</div>
												<div className="me-2">
													<i className="uil uil-arrow-right"></i>
												</div>
												{formatMoney(item.newWholesalePrice)}
											</div>
										</td>
										{(!reduxSettings.tableHistory || reduxSettings.tableHistory?.bankPrice) &&
											<td>
												<div className="d-flex justify-content-center">
													<div className="me-2">{formatMoney(item.oldBankPrice)}</div>
													<div className="me-2">
														<i className="uil uil-arrow-right"></i>
													</div>
													{formatMoney(item.newBankPrice)}
												</div>
											</td>
										}
										<td>
											<div className="d-flex justify-content-center">
												<div className="me-2">{formatMoney(item.oldSalePrice)}</div>
												<div className="me-2">
													<i className="uil uil-arrow-right"></i>
												</div>
												{formatMoney(item.newSalePrice)}
											</div>
										</td>
										{/* <td className="text-center">
											{item.createdBy}
										</td> */}
										<td className="text-center">
											{formatDateWithTime(item.createdDate)}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableHistory'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
