// App.js
import React, { useState } from "react";
import Draggable from "react-draggable";

import "./test.css";
import { useTranslation } from "react-i18next";

function App() {
	const { t } = useTranslation()
	const px = 3.7795275591
	const [priceTag, setPriceTag] = useState({
		width: '60',
		height: '30',
	});
	const [priceTagComponents, setPriceTagComponents] = useState([]);
	const [selectedComponent, setSelectedComponent] = useState({
		text: '',
		fontSize: 0,
		rotation: 0,
	});

	const componentsList = [
		{ type: "productName", text: "PRODUCT", value: "ВАФЛИ СЛАДОНЕЖ С ШОКОЛАДНЫМИ ШОКОЛАДНЫМИ" },
		{ type: "price", text: "PRICE", value: "175000" },
		{ type: "posName", text: "POS", value: "Pos market" },
		{ type: "date", text: "DATE", value: new Date() },
		{ type: "oldPrice", text: "OLD_PRICE", value: "175000" },
		{ type: "barcode", text: "BARCODE", value: "6921734977878А" },
	];

	const addComponentToPriceTag = (component) => {
		const exists = priceTagComponents.some(
			(comp) => comp.text === component.text
		);
		if (!exists) {
			setPriceTagComponents([...priceTagComponents, component]);
		} else {
			alert("Этот компонент уже добавлен.");
		}
	};

	const updateComponent = (index, updatedComponent) => {
		const updatedComponents = [...priceTagComponents];
		updatedComponents[index] = updatedComponent;
		setPriceTagComponents(updatedComponents);
		setSelectedComponent(updatedComponent);
	};

	const handleNumberChange = (e) => {
		const value = parseInt(e.target.value, 10);
		var componentCopy = { ...selectedComponent }
		componentCopy[e.target.name] = value
		if (!isNaN(value)) {
			updateComponent(selectedComponent.index, componentCopy);
		}
	};

	return (
		<div className="row custom-price-tag">
			{/* Controls */}
			<div className="col-md-3">
				<div className="component-picker">
					{componentsList.map((component, index) => (
						<button
							key={index}
							onClick={() =>
								addComponentToPriceTag({
									...component,
									fontSize: 16,
									rotation: 0,
									position: { x: 10, y: 10 },
								})
							}
							className="component-button"
						>
							{t(component.text)}
						</button>
					))}
				</div>
			</div>
			{/* Controls */}

			<div className="col-md-4">
				<div className="editor">
					{/* Price tag */}
					<div>
						<div className="price-tag-inputs">
							Размер
							<input type="text" className="price-tag-input" value={priceTag.width}
								onChange={(e) => setPriceTag({ ...priceTag, width: e.target.value.replace(/[^0-9.]/g, '') })} />
							x
							<input type="text" className="price-tag-input" value={priceTag.height}
								onChange={(e) => setPriceTag({ ...priceTag, height: e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
					</div>
					<div className="price-tag" style={{ width: priceTag.width * px, height: priceTag.height * px }}>
						{priceTagComponents.map((component, index) => (
							<Draggable
								key={index}
								bounds="parent"
								onStop={() => setSelectedComponent({ ...component, index })}
								position={{ x: component.position.x, y: component.position.y }} 
							>
								<div
									style={{
										position: "absolute",
										transform: `translate(${component.position.x}px, ${component.position.y}px) rotate(${component.rotation}deg)`,
										transformOrigin: 'top left',
										fontSize: `${component.fontSize}px`,
									}}
									className={"draggable-item max-line-1 " + (selectedComponent.text === component.text ? 'selected' : '')}
								>
									{component.value}
								</div>
							</Draggable>
						))}
					</div>
				</div>
			</div>

			<div className="col-md-5">
				<div className="controls">
					<div className="form-group">
						<label>Размер текста:</label>
						<input
							type="number"
							value={selectedComponent.fontSize || 16}
							name="fontSize"
							onChange={handleNumberChange}
							className="form-control"
						/>
					</div>
					<div className="form-group">
						<label>Угол поворота:</label>
						<input
							type="text"
							value={selectedComponent.rotation}
							name="rotation"
							onChange={handleNumberChange}
							className="form-control"
						/>
					</div>
				</div>
			</div>

		</div>
	);
}

export default App;
