import React, { useState } from 'react'
import Barcode from 'react-barcode'
//import { useSelector } from 'react-redux'
import { todayDate, todayDDMMYYYY, formatMoney } from 'helpers/helpers'

import logoIcon from "assets/images/logo_icon.svg";

function PriceTag60x30({
	data,
	selectedPriceTag,
	selectPriceTag,
	printProducts,
	printRef,
	cashbox,
	settings = {
		activatePrice: 0,
		activeA4: false,
		activeDate: true,
		activeOldPrice: false,
		barcode: false,
		hideOldPrice: false,
		format: 'CODE128',
	},
}) {
	//const reduxSettings = useSelector(state => state.settings.settings)

	const [image, setImage] = useState(logoIcon)
	const fileUpload = (e) => {
		const file = e.target.files[0]
		setImage(URL.createObjectURL(file))
	}

	function getSeperatedPrice(price, index = 0) {
		const myArray = price.split(" ");
		if (index === 0) {
			return myArray[0]
		}
		if (index === 1) {
			if (myArray.length === 2) {
				return myArray[1]
			} else if (myArray.length === 3) {
				return myArray[1] + ' ' + myArray[2]
			} else {
				return 0
			}
		}
	}

	return (
		<>
			<div className="d-flex flex-wrap">
				{/* PriceTag_1 ( 60x30 ) */}
				<div className={"price_tag_body_60x30 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag60x30_1 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag60x30_1')}>
					<div className="fz6 text-center">{data.posName}</div>
					<div className="vertical-center">
						<div className="price_tag_body_60x30_1_name">{data.productName}</div>
					</div>
					<div className="d-flex justify-content-center price_tag_body_60x30_1_price">
						<div className={'d-flex ' + (formatMoney(data.salePrice, 0).length > 11 ? "fz22" : "fz26")}>
							<div className="vertical-center me-1">
								<span className="fw-700 text-nowrap">
									{formatMoney(data.salePrice, 0)}
								</span>
							</div>
						</div>
						<div className={'vertical-center ' + (formatMoney(data.salePrice, 0).length > 11 ? "fz22" : "fz26")}>
							<b>{cashbox.defaultCurrency}</b>
						</div>
					</div>
					<div className="d-flex justify-content-center barcode">
						<div>
							<Barcode value={data.barcode} width={1.4} height={18} background="transparent" textMargin={0}
								fontOptions="bold" fontSize={10} format={settings.format} />
						</div>
					</div>
					<div className="fz10 date">{todayDate()}</div>
				</div>
				{/* PriceTag_1 ( 60x30 ) */}

				{/* PriceTag_2 ( 60x30 ) */}
				<div className={"price_tag_body_60x30 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag60x30_2 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag60x30_2')}>
					<div className="fz10 text-center vertical-rl position-absolute h-100">{data.posName}</div>
					<div className="vertical-center">
						<div className="price_tag_body_60x30_2_name">{data.productName}</div>
					</div>
					<div className="d-flex justify-content-center price">
						<div className={formatMoney(data.salePrice, 0).length > 9 ? "fz12" : "fz30"}>
							<div className="vertical-center me-1">
								<span className="fw-700">
									{formatMoney(data.salePrice, 0)}
								</span>
							</div>
						</div>
						<div className={'vertical-center ' + (formatMoney(data.salePrice, 0).length > 9 ? "fz12" : "fz30")}>
							<b>{cashbox.defaultCurrency}</b>
						</div>
					</div>
					<div className="d-flex justify-content-center barcode">
						<div>
							<Barcode value={data.barcode} width={1.5} height={20} background="transparent" textMargin={0}
								fontOptions="bold" fontSize={10} format={settings.format} />
						</div>
					</div>
					<div className="fz10 date">{todayDate()}</div>
				</div>
				{/* PriceTag_2 ( 60x30 ) */}

				{/* PriceTag_3 ( 60x30 ) */}
				<div className={"price_tag_body_60x30 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag60x30_3 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag60x30_3')}>
					<div className="fz10 text-center vertical-rl position-absolute h-100">{data.posName}</div>
					<div className="vertical-center">
						<div className="price_tag_body_60x30_3_name">{data.productName}</div>
					</div>
					<div className="price_tag_img">
						<img src={image} width="50" height="50" alt="" />
						<input type="file" width="50" height="50"
							onChange={(e) => fileUpload(e)} />
					</div>
					<div className="d-flex justify-content-end price_tag_body_60x30_3_price me-1">
						<div className={formatMoney(data.salePrice, 0).length > 9 ? "fz12" : "fz30"}>
							<div className="vertical-center h-100 me-1">
								<span className="fw-700">
									{formatMoney(data.salePrice, 0)}
								</span>
							</div>
						</div>
						<div className={'vertical-center ' + (formatMoney(data.salePrice, 0).length > 7 ? "fz12" : "fz30")}>
							<b>{cashbox.defaultCurrency}</b>
						</div>
					</div>
					<div className="d-flex justify-content-end">
						<div>
							<Barcode value={data.barcode} width={1.1} height={18} background="transparent" textMargin={0}
								fontOptions="bold" fontSize={10} format={settings.format} />
						</div>
					</div>
					<div className="fz10 date">{todayDate()}</div>
				</div>
				{/* PriceTag_3 ( 60x30 ) */}

				{/* PriceTag_4 ( 60x30 ) */}
				<div className={"price_tag_body_60x30 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag60x30_4 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag60x30_4')}>
					<div className="fz6 text-center">{data.posName}</div>
					<div className="vertical-center">
						<div className="price_tag_body_60x30_4_name">{data.productName}</div>
					</div>
					<div className="price_tag_discount">
						{!settings.hideOldPrice &&
							<span className="fw-700 fz24">{formatMoney(data.actionPrice, 0)}</span>
						}

					</div>
					<div className="d-flex justify-content-end price_tag_body_60x30_4_price me-1">
						<div className={formatMoney(data.salePrice, 0).length > 9 ? "fz12" : "fz30"}>
							<div className="vertical-center h-100 me-1">
								<span className="fw-700">
									{formatMoney(data.salePrice, 0)}
								</span>
							</div>
						</div>
						<div className={'vertical-center ' + (formatMoney(data.salePrice, 0).length > 9 ? "fz12" : "fz30")}>
							<b>{cashbox.defaultCurrency}</b>
						</div>
					</div>
					<div className="d-flex justify-content-end">
						<div>
							<Barcode value={data.barcode} width={1} height={18} background="transparent" textMargin={0}
								fontOptions="bold" fontSize={10} format={settings.format} />
						</div>
					</div>
					<div className="fz10 date">{todayDate()}</div>
				</div>
				{/* PriceTag_4 ( 60x30 ) */}

				{/* PriceTag_5 ( 60x30 ) */}
				<div className={"price_tag_body_60x30 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag60x30_5 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag60x30_5')}>
					<div className="fz10 date-top-right">{todayDDMMYYYY()}</div>
					<div className="mt-3 ms-1 fz12 overflow-hidden">
						<div className="text-start text-uppercase text-nowrap">
							{data?.productName?.length > 34 ? data.productName.slice(0, 34) + "..." : data.productName}
						</div>
					</div>
					<div className="d-flex justify-content-end price me-1">
						<div className={formatMoney(data.salePrice, 0).length > 9 ? "fz12" : "fz30"}>
							<div className="vertical-center h-100 me-1">
								<span className="fw-700">
									{formatMoney(data.salePrice, 0)}
								</span>
							</div>
						</div>
						<div className={'vertical-center ' + (formatMoney(data.salePrice, 0).length > 9 ? "fz12" : "fz30")}>
							<b>{cashbox.defaultCurrency}</b>
						</div>
					</div>
					<div className="d-flex justify-content-between barcode mt-2">
						<div>
							<Barcode value={data.barcode} width={1} height={18} background="transparent" textMargin={0}
								fontOptions="bold" fontSize={10} format={settings.format} />
						</div>
						<div className="vertical-center">
							<b className="fz12 text-end text-nowrap me-1">Цена за: 1 шт.</b>
							<div className="fz12 text-end me-1">{data.posName}</div>
						</div>
					</div>
				</div>
				{/* PriceTag_5 ( 60x30 ) */}

				{/* PriceTag_6 ( 60x30 ) */}
				<div className={"price_tag_body_60x30 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag60x30_6 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag60x30_6')}>
					<div className="fz6 text-center">{data.posName}</div>
					<div className="vertical-center">
						<div className="price_tag_body_60x30_1_name">{data.productName}</div>
					</div>
					<div className="d-flex justify-content-center barcode">
						<div>
							<Barcode value={data.barcode} width={1.2} height={18} background="transparent" textMargin={0}
								fontOptions="bold" fontSize={10} format={settings.format} />
						</div>
					</div>
					<div className="fz10 date">{todayDate()}</div>
				</div>
				{/* PriceTag_6 ( 60x30 ) */}

				{/* PriceTag_7 ( 60x30 ) */}
				<div className={"price_tag_body_60x30 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag60x30_7 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag60x30_7')}>
					<div className="price_tag_body_60x30_7_pos fz18 text-center"><b>{data.posName}</b></div>
					<div className="vertical-center">
						<div className="price_tag_body_60x30_7_name">{data.productName}</div>
					</div>
					<div className="d-flex justify-content-center price_tag_body_60x30_1_price">
						<div className={'d-flex ' + (formatMoney(data.salePrice, 0).length > 11 ? "fz22" : "fz26")}>
							<div className="vertical-center me-1">
								<span className="fw-700 text-nowrap">
									{formatMoney(data.salePrice, 0)}
								</span>
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-center barcode">
						<div>
							<Barcode value={data.barcode} width={1.2} height={14} background="transparent" textMargin={0}
								fontOptions="bold" fontSize={10} format={settings.format} />
						</div>
					</div>
				</div>
				{/* PriceTag_7 ( 60x30 ) */}

				{/* PriceTag_8 ( 60x30 ) */}
				<div className={"price_tag_body_60x30 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag60x30_8 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag60x30_8')}>
					<div className="d-flex justify-content-between price_tag_body_60x30_8_barcode">
						<Barcode value={data.barcode} width={1} height={16} background="transparent" textMargin={0}
							fontOptions="bold" fontSize={10} format={settings.format} />
						<div className="price-tag-price-wrapper">
							{!settings.hideOldPrice &&
								<div>
									<b className="fz20">
										{formatMoney(data.actionPrice, 0)}
									</b>
								</div>
							}
							<hr className="price-tag-throw-line" />
						</div>
					</div>
					<div className="d-flex justify-content-center price_tag_body_60x30_8_price">
						<div className="d-flex fz30">
							<div className="vertical-center">
								<span className="price_tag_body_60x30_8_price_left">{getSeperatedPrice(formatMoney(data.salePrice, 0), 0)}</span>
							</div>
							<div className="price_tag_body_60x30_8_price_right">
								<div>{cashbox.defaultCurrency}</div>
								<div className="text-center">
									{getSeperatedPrice(formatMoney(data.salePrice, 0), 1)}
								</div>
							</div>
						</div>
					</div>
					<div className="vertical-center">
						<div className="price_tag_body_60x30_8_name">{data.productName}</div>
					</div>
					<div className="fz10 date">{todayDate()}</div>
				</div>
				{/* PriceTag_8 ( 60x30 ) */}

				{/* PriceTag_9 ( 60x30 ) */}
				<div className={"price_tag_body_60x30 price-tag-border mb-3 me-3 d-none " + (selectedPriceTag.priceTag60x30_9 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag60x30_9')}>
					<div className="fz10 date">{todayDate()}</div>
					<div className="d-flex justify-content-between price_tag_body_60x30_9_barcode">
						<div className="price_tag_body_60x30_8_name">{data.productName}</div>
						<div className="price-tag-price-wrapper">
							{!settings.hideOldPrice &&
								<div>
									<b className="fz20">
										{formatMoney(data.actionPrice, 0)}
									</b>
								</div>
							}
							<hr className="price-tag-throw-line" />
						</div>
					</div>
					<div className="d-flex justify-content-center price_tag_body_60x30_8_price">
						<div className="d-flex fz30">
							<div className="price_tag_body_60x30_8_price_right">
								<div className="text-center">
									{formatMoney(data.salePrice, 0)}
								</div>
							</div>
						</div>
					</div>
					<Barcode value={data.barcode} width={1} height={16} background="transparent" textMargin={0}
						fontOptions="bold" fontSize={10} format={settings.format} />
				</div>
				{/* PriceTag_9 ( 60x30 ) */}

				{/* PriceTag_10 ( 60x30 ) */}
				<div className={"price_tag_body_60x30 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag60x30_10 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag60x30_10') + console.log(selectedPriceTag)}>
					<div className="fz10 text-center vertical-rl position-absolute h-100">{data.posName}</div>
					<div className="vertical-center">
						<div className="price_tag_body_60x30_10_name">{data.productName}</div>
					</div>
					<div className="price_tag_img second">
						<img src={image} width="40" height="40" alt="" />
						<input type="file" width="40" height="40"
							onChange={(e) => fileUpload(e)} />
					</div>
					<div className="d-flex justify-content-end price_tag_body_60x30_10_price me-1">
						<div className={formatMoney(data.salePrice, 0).length > 9 ? "fz12" : "fz30"}>
							<div className="vertical-center h-100 me-1">
								<span className="fw-700">
									{formatMoney(data.salePrice, 0)}
								</span>
							</div>
						</div>
						<div className={'vertical-center ' + (formatMoney(data.salePrice, 0).length > 7 ? "fz12" : "fz30")}>
							<b>{cashbox.defaultCurrency}</b>
						</div>
					</div>
					<div className="fz10 date">{todayDate()}</div>
				</div>
				{/* PriceTag_10 ( 60x30 ) */}
			</div>

			<div className="d-none">
				{selectedPriceTag.priceTag60x30_1 &&
					<div ref={printRef} className={"price-tag-wrap " + (settings.a4 ? 'a4 ' : ' ') + (settings.activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_60x30 page-breaker" key={index}>
								<div className="fz6 text-center">{product.posName}</div>
								<div className="vertical-center">
									<div className="price_tag_body_60x30_1_name">{product.productName}</div>
								</div>
								<div className="d-flex justify-content-center price_tag_body_60x30_1_price">
									<div className={'d-flex ' + (formatMoney(product.salePrice, 0).length > 11 ? "fz22" : "fz26")}>
										<div className="vertical-center me-1">
											<span className="fw-700 text-nowrap">
												{formatMoney(product.salePrice, 0)}
											</span>
										</div>
									</div>
									<div className={'vertical-center ' + (formatMoney(product.salePrice, 0).length > 11 ? "fz22" : "fz26")}>
										<b>{cashbox.defaultCurrency}</b>
									</div>
								</div>
								<div className="d-flex justify-content-center barcode">
									<div>
										<Barcode value={product.barcode} width={1.4} height={18} background="transparent" textMargin={0}
											fontOptions="bold" fontSize={10} format={settings.format} />
									</div>
								</div>
								<div className="fz10 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag60x30_2 &&
					<div ref={printRef} className={"price-tag-wrap " + (settings.a4 ? 'a4 ' : ' ') + (settings.activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_60x30 page-breaker" key={index}>
								<div className="fz10 text-center vertical-rl position-absolute"
									style={{ 'height': '105px' }}>{product.posName}</div>
								<div className="vertical-center">
									<div className="price_tag_body_60x30_2_name">{product.productName}</div>
								</div>
								<div className="d-flex justify-content-center price">
									<div className={formatMoney(product.salePrice, 0).length > 9 ? "fz12" : "fz30"}>
										<div className="vertical-center me-1">
											<span className="fw-700">
												{formatMoney(product.salePrice, 0)}
											</span>
										</div>
									</div>
									<div className={'vertical-center ' + (formatMoney(product.salePrice, 0).length > 9 ? "fz12" : "fz30")}>
										<b>{cashbox.defaultCurrency}</b>
									</div>
								</div>
								<div className="d-flex justify-content-center barcode">
									<div>
										<Barcode value={product.barcode} width={1.5} height={20} background="transparent" textMargin={0}
											fontOptions="bold" fontSize={10} format={settings.format} />
									</div>
								</div>
								<div className="fz10 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag60x30_3 &&
					<div ref={printRef} className={"price-tag-wrap " + (settings.a4 ? 'a4 ' : ' ') + (settings.activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_60x30 page-breaker" key={index}>
								<div className="fz10 text-center vertical-rl position-absolute"
									style={{ 'height': '105px' }}>{product.posName}</div>
								<div className="vertical-center">
									<div className="price_tag_body_60x30_3_name">{product.productName}</div>
								</div>
								<div className="price_tag_img">
									<img src={image} width="50" height="50" alt="" />
									<input type="file" width="50" height="50"
										onChange={(e) => fileUpload(e)} />
								</div>
								<div className="d-flex justify-content-end price_tag_body_60x30_3_price me-1">
									<div className={formatMoney(product.salePrice, 0).length > 9 ? "fz12" : "fz30"}>
										<div className="vertical-center h-100 me-1">
											<span className="fw-700">
												{formatMoney(product.salePrice, 0)}
											</span>
										</div>
									</div>
									<div className={'vertical-center ' + (formatMoney(product.salePrice, 0).length > 7 ? "fz12" : "fz30")}>
										<b>{cashbox.defaultCurrency}</b>
									</div>
								</div>
								<div className="d-flex justify-content-end">
									<div>
										<Barcode value={product.barcode} width={1.1} height={18} background="transparent" textMargin={0}
											fontOptions="bold" fontSize={10} format={settings.format} />
									</div>
								</div>
								<div className="fz10 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag60x30_4 &&
					<div ref={printRef} className={"price-tag-wrap " + (settings.a4 ? 'a4 ' : ' ') + (settings.activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_60x30 page-breaker" key={index}>
								<div className="fz6 text-center">{product.posName}</div>
								<div className="vertical-center">
									<div className="price_tag_body_60x30_4_name">{product.productName}</div>
								</div>
								<div className="price_tag_discount">
									{!settings.hideOldPrice &&
										<span className="fw-700 fz30">{formatMoney(data.actionPrice, 0)}</span>
									}
								</div>
								<div className="d-flex justify-content-end price_tag_body_60x30_4_price me-1">
									<div className={formatMoney(product.salePrice, 0).length > 9 ? "fz12" : "fz30"}>
										<div className="vertical-center h-100 me-1">
											<span className="fw-700">
												{formatMoney(product.salePrice, 0)}
											</span>
										</div>
									</div>
									<div className={'vertical-center ' + (formatMoney(product.salePrice, 0).length > 9 ? "fz12" : "fz30")}>
										<b>{cashbox.defaultCurrency}</b>
									</div>
								</div>
								<div className="d-flex justify-content-end">
									<div>
										<Barcode value={product.barcode} width={1} height={18} background="transparent" textMargin={0}
											fontOptions="bold" fontSize={10} format={settings.format} />
									</div>
								</div>
								<div className="fz10 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag60x30_5 &&
					<div ref={printRef} className={"price-tag-wrap " + (settings.a4 ? 'a4 ' : ' ') + (settings.activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_60x30 page-breaker" key={index}>
								<div className="fz10 date-top-right">{todayDDMMYYYY()}</div>
								<div className="mt-3 ms-1 fz12 overflow-hidden">
									<div className="text-start text-uppercase text-nowrap">
										{product?.productName?.length > 34 ? product.productName.slice(0, 34) + "..." : product.productName}
									</div>
								</div>
								<div className="d-flex justify-content-end price me-1">
									<div className={formatMoney(product.salePrice, 0).length > 9 ? "fz12" : "fz30"}>
										<div className="vertical-center h-100 me-1">
											<span className="fw-700">
												{formatMoney(product.salePrice, 0)}
											</span>
										</div>
									</div>
									<div className={'vertical-center ' + (formatMoney(product.salePrice, 0).length > 9 ? "fz12" : "fz30")}>
										<b>{cashbox.defaultCurrency}</b>
									</div>
								</div>
								<div className="d-flex justify-content-between barcode mt-2">
									<div>
										<Barcode value={product.barcode} width={1} height={18} background="transparent" textMargin={0}
											fontOptions="bold" fontSize={10} format={settings.format} />
									</div>
									<div className="vertical-center">
										<b className="fz12 text-end text-nowrap me-1">Цена за: 1 шт.</b>
										<div className="fz12 text-end me-1">{product.posName}</div>
									</div>
								</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag60x30_6 &&
					<div ref={printRef} className={"price-tag-wrap " + (settings.a4 ? 'a4 ' : ' ') + (settings.activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_60x30 page-breaker" key={index}>
								<div className="fz6 text-center">{product.posName}</div>
								<div className="vertical-center">
									<div className="price_tag_body_60x30_1_name">{product.productName}</div>
								</div>
								<div className="d-flex justify-content-center barcode">
									<div>
										<Barcode value={product.barcode} width={1.2} height={18} background="transparent" textMargin={0}
											fontOptions="bold" fontSize={10} format={settings.format} />
									</div>
								</div>
								<div className="fz10 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag60x30_7 &&
					<div ref={printRef} className={"price-tag-wrap " + (settings.a4 ? 'a4 ' : ' ') + (settings.activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_60x30 page-breaker" key={index}
								onClick={() => selectPriceTag('priceTag60x30_7')}>
								<div className="price_tag_body_60x30_7_pos fz18 text-center"><b>{product.posName}</b></div>
								<div className="vertical-center">
									<div className="price_tag_body_60x30_7_name">{product.productName}</div>
								</div>
								<div className="d-flex justify-content-center price_tag_body_60x30_1_price">
									<div className={'d-flex ' + (formatMoney(product.salePrice, 0).length > 11 ? "fz22" : "fz26")}>
										<div className="vertical-center me-1">
											<span className="fw-700 text-nowrap">
												{formatMoney(product.salePrice, 0)}
											</span>
										</div>
									</div>
								</div>
								<div className="d-flex justify-content-center barcode">
									<div>
										<Barcode value={product.barcode} width={1.2} height={14} background="transparent" textMargin={0}
											fontOptions="bold" fontSize={10} format={settings.format} />
									</div>
								</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag60x30_8 &&
					<div ref={printRef} className={"price-tag-wrap " + (settings.a4 ? 'a4 ' : ' ') + (settings.activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_60x30 page-breaker" key={index}
								onClick={() => selectPriceTag('priceTag60x30_8')}>
								<div className="d-flex justify-content-between barcode">
									<Barcode value={product.barcode} width={1} height={16} background="transparent" textMargin={0}
										fontOptions="bold" fontSize={10} format={settings.format} />
									<div className="price-tag-price-wrapper">
										{!settings.hideOldPrice &&
											<div>
												<b className="fz20">
													{formatMoney(product.actionPrice, 0)}
												</b>
											</div>
										}
										<hr className="price-tag-throw-line" />
									</div>
								</div>
								<div className="d-flex justify-content-center price_tag_body_60x30_8_price_print">
									<div className="d-flex fz30">
										<div className="vertical-center">
											<span className="price_tag_body_60x30_8_price_left">{getSeperatedPrice(formatMoney(product.salePrice, 0), 0)}</span>
										</div>
										<div className="price_tag_body_60x30_8_price_right">
											<div>{cashbox.defaultCurrency}</div>
											<div className="text-center">
												{getSeperatedPrice(formatMoney(product.salePrice, 0), 1)}
											</div>
										</div>
									</div>
								</div>
								<div className="vertical-center mt-3">
									<div className="price_tag_body_60x30_8_name">{product.productName}</div>
								</div>
								<div className="fz10 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag60x30_9 &&
					<div ref={printRef} className={"price-tag-wrap " + (settings.a4 ? 'a4 ' : ' ') + (settings.activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_60x30 page-breaker" key={index}
								onClick={() => selectPriceTag('priceTag60x30_9')}>
								<div className="d-flex justify-content-between barcode">
									<Barcode value={product.barcode} width={1} height={16} background="transparent" textMargin={0}
										fontOptions="bold" fontSize={10} format={settings.format} />
									<div className="price-tag-price-wrapper">
										{!settings.hideOldPrice &&
											<div>
												<b className="fz22">
													{formatMoney(product.actionPrice, 0)}
												</b>
											</div>
										}
										<hr className="price-tag-throw-line" />
									</div>
								</div>
								<div className="d-flex justify-content-center price_tag_body_60x30_1_price">
									<div className="d-flex fz30">
										<div className="vertical-center">
											<span className="price_tag_body_60x30_8_price_left">{getSeperatedPrice(formatMoney(product.salePrice, 0), 0)}</span>
										</div>
										<div className="price_tag_body_60x30_8_price_right">
											<div>{cashbox.defaultCurrency}</div>
											<div className="text-center">
												{getSeperatedPrice(formatMoney(product.salePrice, 0), 1)}
											</div>
										</div>
									</div>
								</div>
								<div className="vertical-center mt-3">
									<div className="price_tag_body_60x30_8_name">{product.productName}</div>
								</div>
								<div className="fz10 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag60x30_10 &&
					<div ref={printRef} className={"price-tag-wrap " + (settings.a4 ? 'a4 ' : ' ') + (settings.activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_60x30 page-breaker" key={index}>
								<div className="fz10 text-center vertical-rl position-absolute"
									style={{ 'height': '105px' }}>{product.posName}</div>
								<div className="vertical-center">
									<div className="price_tag_body_60x30_10_name">{product.productName}</div>
								</div>
								<div className="price_tag_img second">
									<img src={image} width="40" height="40" alt="" />
									<input type="file" width="40" height="40"
										onChange={(e) => fileUpload(e)} />
								</div>
								<div className="d-flex justify-content-end price_tag_body_60x30_10_price me-1">
									<div className={formatMoney(product.salePrice, 0).length > 9 ? "fz12" : "fz30"}>
										<div className="vertical-center h-100 me-1">
											<span className="fw-700">
												{formatMoney(product.salePrice, 0)}
											</span>
										</div>
									</div>
									<div className={'vertical-center ' + (formatMoney(product.salePrice, 0).length > 7 ? "fz12" : "fz30")}>
										<b>{cashbox.defaultCurrency}</b>
									</div>
								</div>
								<div className="fz10 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}
			</div>
		</>
	)
}

export default PriceTag60x30