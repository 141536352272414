import React from "react";
import { Link } from "react-router-dom";

//i18n
import SidebarContent from "./SidebarContent";

import logoIcon from "../../assets/images/logo_icon.svg";

const Sidebar = () => {

	return (
		<div className="vertical-menu">
			<div className="navbar-brand-box">
				<Link to="/welcome" className="logo logo-dark" tabIndex="-1">
					<span className="logo-sm">
						<img src={logoIcon} alt="logoIcon"  />
					</span>
					<span className="logo-lg">
						<img src={logoIcon} alt="logo" width={35} height={35} />
					</span>
				</Link>
			</div>
			<div data-simplebar className="h-100">
				<SidebarContent />
			</div>
		</div>
	);
};

export default Sidebar