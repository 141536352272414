const wms = [
	{
		icon: "uil-store",
		title: 'WAREHOUSE',
		show: false,
		children: [
			{
				icon: "uil-store",
				title: 'WAREHOUSE',
				show: false,
				children: [
					{
						title: true,
						i18n: "WAREHOUSE"
					},
					{
						url: "/update/dashboard", // wms/dashboard
						i18n: "HOME",
						icon: "uil-chart-growth",
						role: "WMS_MAIN"
					},
					{
						url: "/wms/documents-in",
						i18n: "GOOD_RECEPTION",
						icon: "uil-bring-front",
						role: "WMS_DOCUMENT_IN"
					},
					{
						url: "/wms/documents-out",
						i18n: "RETURN_OF_GOODS",
						icon: "uil-backspace",
						role: "WMS_DOCUMENT_OUT"
					},
					{
						url: "/wms/revaluation",
						i18n: "REVALUATION",
						icon: "uil-newspaper",
						role: "WMS_REVALUATION"
					},
					{
						url: "/wms/write-off",
						i18n: "WRITE_OFF",
						icon: "uil-trash-alt",
						role: "WMS_WRITE_OFF"
					},
					{
						url: "/wms/inventory", //wms/inventory
						i18n: "INVENTORY",
						icon: "uil-calculator-alt",
						role: "WMS_INVENTORY"
					},
					{
						url: "/wms/wms-transfer", //wms/transfer
						i18n: "DISPLACEMENT_POS",
						icon: "uil-truck",
						role: "WMS_TRANSFER_WMS"
					},
					{
						url: "/wms/orders",
						i18n: "ORDERS",
						icon: "uil-import",
						role: "WMS_ORDERS"
					},
					{
						url: "/wms/scale",
						i18n: "SCALE",
						icon: "uil-balance-scale",
						role: "WMS_SCALE"
					},
					{
						url: "/wms/grouping",
						i18n: "GROUPING",
						icon: "uil-object-ungroup",
						role: "WMS_PRODUCT_GROUP"
					},
					{
						url: "/wms/notifications",
						i18n: "NOTIFICATIONS",
						icon: "uil-bell",
						role: "WMS_NOTIFICATIONS"
					},
					{
						url: "/wms/price-tags",
						i18n: "PRICE_TAGS",
						icon: "uil-pricetag-alt",
						role: "WMS_PRICE_TAGS"
					},
					{
						url: "/wms/reports", // wms/reports
						i18n: "REPORTS",
						icon: "uil-chart-pie-alt",
						role: "WMS_REPORTS"
					},
				]
			},
			// {
			// 	icon: "uil-book-open",
			// 	title: 'GENERAL',
			// 	show: false,
			// 	children: [
			// 		{
			// 			url: "/workers",
			// 			i18n: "MY_USERS",
			// 			icon: "uil-user-square",
			// 			role: "MAIN_USERS"
			// 		},
			// 		{
			// 			url: "/products",
			// 			i18n: "PRODUCTS",
			// 			icon: "uil-shopping-cart",
			// 			role: "MAIN_PRODUCTS"
			// 		},
			// 		{
			// 			url: "/suppliers",
			// 			i18n: "SUPPLIERS",
			// 			icon: "uil-truck-loading",
			// 			role: "MAIN_ORGANIZATION"
			// 		},
			// 		{
			// 			url: "/clients",
			// 			i18n: "CLIENTS",
			// 			icon: "uil-users-alt",
			// 			role: "MAIN_CLIENTS"
			// 		},
			// 		{
			// 			url: "/currency",
			// 			i18n: "CURRENCY",
			// 			icon: "uil-money-bill",
			// 			role: "MAIN_CURRENCY"
			// 		},
			// 		{
			// 			url: "/expense",
			// 			i18n: "EXPENSES",
			// 			icon: "uil-money-insert",
			// 			role: "MAIN_EXPENSE"
			// 		},
			// 	]
			// },
			{
				icon: "uil-setting",
				title: 'SETTINGS',
				show: false,
				role: "WMS_SETTINGS",
				children: [
					{
						title: true,
						i18n: "SETTINGS"
					},
					{
						url: "/settings",
						i18n: "SETTINGS",
						icon: "uil-setting",
						role: "WMS_SETTINGS"
					},
					{
						url: "/warehouse",
						i18n: "WAREHOUSE",
						icon: "uil-store",
						role: "WMS_SETTINGS_WMS"
					},
					// {
					// 	url: "/update/wms-cashbox", // wms-cashbox
					// 	i18n: "WAREHOUSE_CASHIER",
					// 	icon: "uil-store",
					// 	role: "WMS_SETTINGS_CASHBOX"
					// },
				]
			}
		]
	}
]

export default wms